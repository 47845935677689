import React from "react"
import { CSVLink } from "react-csv"

export default function GrapeInfectionEventsLog({ data }) {
  const tableData = [...data]

  const dataCSV = tableData.map((d) => {
    return {
      startingDateTime: d.wetEventStartDateTime,
      endingDateTime: d.wetEventEndDateTime,
      HoursLW: d.wetHours,
      avgTemp: d.avgT !== "M" ? d.avgT.toFixed(1) : "M",
      totalRain: d.totalPrcp !== "M" ? d.totalPrcp.toFixed(2) : "M",
      phomopsis: d.phomopsis,
      blackRot: d.blackRot,
      combined: "Yes",
    }
  })

  if (!data) return null
  return (
    <div className="w-full">
      <div className="flex items-center justify-between mb-3">
        <h2 className="text-xl font-semibold text-gray-600 md:text-2xl">
          Grape Infection Events Log
        </h2>
        <div className="flex justify-center rounded-md shadow-sm">
          <button
            type="button"
            aria-label="download results table data in csv format"
            className="inline-flex items-center p-2 text-sm font-medium leading-4 text-white transition duration-150 ease-in-out border border-transparent rounded-md sm:px-3 sm:py-2 bg-secondary-800 hover:bg-secondary-700 focus:outline-none focus:border-secondary-900 focus:shadow-outline-secondary active:bg-secondary-900"
          >
            <svg
              className="-ml-0.5 mr-2 h-4 w-4"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
            </svg>

            <CSVLink
              className="text-white no-underline"
              filename={`Grape Infection Events Log.csv`}
              data={dataCSV}
            >
              <span className="hidden text-white sm:inline-block">
                Download
              </span>{" "}
              <span className="text-white">CSV</span>
            </CSVLink>
          </button>
        </div>
      </div>

      <div className="flex flex-col mt-4">
        <div className="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div className="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
            <table className="table min-w-full">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 whitespace-nowrap bg-secondary-800">
                    Starting Date/Time
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 whitespace-nowrap bg-secondary-800">
                    Ending Date/Time
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 bg-secondary-800">
                    Hours LW
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 bg-secondary-800">
                    Avg Temp
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 bg-secondary-800">
                    Total Rain
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 bg-secondary-800 whitespace-nowrap">
                    Phomopsis
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 bg-secondary-800 whitespace-nowrap">
                    Black Rot
                  </th>
                  <th className="px-6 py-3 text-xs leading-4 tracking-wider text-center text-white uppercase border-r border-gray-200 bg-secondary-800 whitespace-nowrap">
                    Combined
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {tableData.reverse().map((day, i) => {
                  return (
                    <tr key={i} className="text-center">
                      <td
                        className={`text-xs sm:text-sm px-4 py-2 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        {day.wetEventStartDateTime}
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-4 py-2 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        {day.wetEventEndDateTime}
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-4 py-2 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        {day.wetHours}
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-4 py-2 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        {day.avgT.toFixed(1)}
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-4 py-2 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        {day.totalPrcp.toFixed(2)}
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-4 py-2 border-b border-gray-200 leading-6  ${
                          day.phomopsis === "Infection"
                            ? `text-red-600 font-bold`
                            : `text-gray-700`
                        }`}
                      >
                        {day.phomopsis}
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-4 py-2 border-b border-gray-200 leading-6  ${
                          day.blackRot === "Infection"
                            ? `text-red-600 font-bold`
                            : `text-gray-700`
                        }`}
                      >
                        {day.blackRot}
                      </td>
                      <td
                        className={`text-xs sm:text-sm px-4 py-2 border-b border-gray-200 leading-6 text-gray-700`}
                      >
                        Yes
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
