import React from "react";
import ModelLayout from "../../components/models/modelLayout";
import GlobalStateContext from "../../context/globalStateContext";
import { useAuth0 } from "../../context/react-auth0-spa";
import { modelInSeason, sdateEdate } from "../../utils/helpers";
import useFetchStationData from "../../utils/hooks/useFetchStationData";
import useStations from "../../utils/hooks/useStations";
import modelData from "./grape-diseases.json";
import Acknowledgments from "./_acknowledgements";
import GrapeInfectionEventsLog from "./_grapeInfectionEventsLog";
import LeafWetnessEventsLog from "./_leafWetnessEventsLog";
import ManagementGuide from "./_managementGuide";
import modelLogic from "./_modelLogic";
import MoreInfo from "./_moreInfo";
import References from "./_references";
import ResultsTable from "./_resultsTable";

const visibleElements = Object.entries(modelData.elements)
  .filter(([_, value]) => value.priority)
  .filter(([key, _]) => !key.includes("user"))
  .map((arr) => ({ ...arr[1], toggleName: arr[0] }))
  .sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

export default function GrapeDiseases() {
  const h1Text = modelData.title;
  const { seasonEnd, elements } = modelData;
  const { user, isAuthenticated } = useAuth0();
  const { stationList, station, setStation, favoriteStations, geoJSON } =
    useStations();
  const { dateOfInterest } = React.useContext(GlobalStateContext);
  const isModelInSeason = modelInSeason(dateOfInterest, modelData);

  const { sdate, edate } = sdateEdate(dateOfInterest, seasonEnd);
  const { isLoading, data } = useFetchStationData(station, sdate, edate);

  let mData = null;
  if (data && isModelInSeason) {
    mData = modelLogic(data, dateOfInterest);
  }

  const [isMoreInfo, setIsMoreInfo] = React.useState(false);
  const [showManagementGuide, setShowManagementGuide] = React.useState(true);
  const [showResultsTable, setShowResultsTable] = React.useState(true);
  const [showLeafWetnessEventsLog, setShowLeafWetnessEventsLog] =
    React.useState(true);
  const [showGrapeInfectionEventsLog, setShowGrapeInfectionEventsLog] =
    React.useState(true);
  const isVisible = station && mData;

  return (
    <ModelLayout
      isAuthenticated={isAuthenticated}
      stationList={user ? favoriteStations : stationList}
      station={station}
      setStation={setStation}
      allStations={stationList}
      geoJSON={geoJSON}
      data={data}
      isModelInSeason={isModelInSeason}
      isLoading={isLoading}
      modelData={modelData}
      visibleElements={visibleElements}
      titleSize={"text-lg lg:text-2xl"}
      moreInfo={<MoreInfo station={station} moreInfo={modelData.moreInfo} />}
      setIsMoreInfo={setIsMoreInfo}
      references={References}
      acknowledgments={Acknowledgments}
      isMoreInfo={isMoreInfo}
      showManagementGuide={showManagementGuide}
      setShowManagementGuide={setShowManagementGuide}
      showResultsTable={showResultsTable}
      setShowResultsTable={setShowResultsTable}
      showResultsTable2={showLeafWetnessEventsLog}
      setShowResultsTable2={setShowLeafWetnessEventsLog}
      showResultsTable3={showGrapeInfectionEventsLog}
      setShowResultsTable3={setShowGrapeInfectionEventsLog}
      tutorialLink={modelData.tutorialLink}
      areStationsFiltered={false}
      h1Text={h1Text}
    >
      {!isModelInSeason && (
        <div className="mb-16 text-lg font-semibold text-center lg:text-xl sm:mb-18 md:mb-20">
          Results from this model will be available beginning March 25.
        </div>
      )}

      {/* Infection Event Log */}
      {isModelInSeason && showResultsTable && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ResultsTable
            station={station}
            modelData={modelData}
            data={mData.diseaseInfectionEvents}
            dateOfInterest={dateOfInterest}
          ></ResultsTable>
        </div>
      )}

      {/* Management Guide */}
      {isModelInSeason && showManagementGuide && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <ManagementGuide
            list={elements.managementGuide.diseases}
            dateOfInterest={dateOfInterest}
          ></ManagementGuide>
        </div>
      )}

      {/* Leaf Wetness Events Log Table */}
      {isModelInSeason && showLeafWetnessEventsLog && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <LeafWetnessEventsLog
            data={mData.leafWetnessEventsLog}
          ></LeafWetnessEventsLog>
        </div>
      )}

      {/* Grape Infection Events Log Table */}
      {isModelInSeason && showGrapeInfectionEventsLog && isVisible && (
        <div className="mb-16 sm:mb-18 md:mb-20">
          <GrapeInfectionEventsLog
            data={mData.grapeInfectionEventsLog}
          ></GrapeInfectionEventsLog>
        </div>
      )}
    </ModelLayout>
  );
}
