import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      Bugaret, Y. 1984. L'excoriose de la vigne: recherches sur le Phomopsis viticola Sacc., nouvelles possibilites de lutte Ph.D. thesis. University of Bordeaux. 160 pp.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      Gadoury, D.M., and Pearson, R.C. 1990. Ascocarp dehiscence and ascospore discharge in Uncinula necator. Phytopathology. 80:393-401
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      Gadoury, D.M., and Pearson, R.C. 1990. Germination of ascospores and infection of Vitis by Uncinula necator. Phytopathology. 80:1198-1203
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
      Spotts, R.A. 1977. Effect of leaf wetness duration and temperature on the infectivity of Guignardia bidwellii on grape leaves. Phytopathology 67:1378-1381
      </p>
    </>
  )
}
