import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
      The NEWA grape diseases model was authored by Juliet Carroll, Wayne Wilcox and Tim Weigle, Cornell University.
      </p>
    </>
  )
}
