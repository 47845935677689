/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from "react"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"
import { getDayOfYear } from "date-fns"
import parse from "html-react-parser"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function ManagementGuide({ list, dateOfInterest }) {
  const stages = Object.values(list)

  let stage = ""
  for (const [key, message] of Object.entries(list)) {
    const dateRange = key.split("|")
    const lowerDate = `${dateOfInterest.year}-${dateRange[0]}`
    const lowerDateDayOfYear = getDayOfYear(new Date(lowerDate))
    const upperDate = `${dateOfInterest.year}-${dateRange[1]}`
    const upperDateDayOfYear = getDayOfYear(new Date(upperDate))

    if (
      dateOfInterest.dayOfYear >= lowerDateDayOfYear &&
      dateOfInterest.dayOfYear <= upperDateDayOfYear
    ) {
      stage = message
    }
  }
  const [selected, setSelected] = useState(stage)

  React.useEffect(() => {
    setSelected(stage)
  }, [stage])

  return (
    <div>
      <div className="mx-auto md:w-72">
        <Listbox value={selected} onChange={setSelected}>
          {({ open }) => (
            <>
              <Listbox.Label className="block font-bold text-gray-700">
                Phenological stage
              </Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-secondary-500 focus:border-secondary-500 sm:text-sm">
                  <span className="block truncate">{selected.value}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {stages.map((stage) => (
                      <Listbox.Option
                        key={stage.id}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-secondary-600"
                              : "text-gray-900",
                            "cursor-default select-none relative py-2 pl-3 pr-9"
                          )
                        }
                        value={stage}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {stage.value}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-secondary-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
      <p className="max-w-2xl mx-auto text-sm text-center">
        Choose the phenology stage for the grape variety of interest to display
        management messages. Concord grape phenology is estimated by the model
        from historical records for this variety.
      </p>

      <div className="flex flex-col mt-4">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 table-fixed">
                <thead className="bg-secondary-800">
                  <tr>
                    <th
                      scope="col"
                      className="w-1/5 px-6 py-3 text-base font-semibold tracking-wider text-left text-white"
                    >
                      Disease
                    </th>
                    <th
                      scope="col"
                      className="w-4/5 px-6 py-3 text-base font-semibold tracking-wider text-left text-white"
                    >
                      Disease Management
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white">
                    <td className="px-6 py-4 text-sm font-extrabold text-gray-500">
                      Phomopsis
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {parse(selected.phomopsis.diseaseManagement)}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="px-6 py-4 text-sm font-extrabold text-gray-500">
                      Powdery Mildew
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {parse(selected.powderyMildew.diseaseManagement)}
                    </td>
                  </tr>
                  <tr className="bg-white">
                    <td className="px-6 py-4 text-sm font-extrabold text-gray-500">
                      Black Rot
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">
                      {parse(selected.blackRot.diseaseManagement)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
