import {
  format,
  differenceInHours,
  isEqual,
  isBefore,
  isAfter,
  subHours,
  isSameDay,
} from "date-fns"

export const phomopDict = {
  46: 13,
  47: 12,
  48: 12,
  49: 12,
  50: 12,
  51: 11,
  52: 11,
  53: 11,
  54: 11,
  55: 10,
  56: 10,
  57: 10,
  58: 9,
  59: 9,
  60: 8,
  61: 8,
  62: 7,
  63: 7,
  64: 7,
  65: 6,
  66: 6,
  67: 6,
  68: 6,
  69: 5,
  70: 5,
  71: 5,
  72: 5,
  73: 5,
  74: 5,
  75: 5,
  76: 5,
  77: 5,
  78: 5,
  79: 5,
  80: 5,
  81: 6,
  82: 6,
  83: 6,
  84: 6,
  85: 6,
  86: 8,
}

export const blackRotDict = {
  50: 24,
  55: 12,
  60: 9,
  65: 8,
  70: 7,
  75: 7,
  80: 6,
  85: 9,
  90: 12,
}

const roundToNearest5 = (x) => Math.round(x / 5) * 5

function determinePhomopsisInfection(wetHours, avgT, totPrcp) {
  const roundedAvgT = Math.round(avgT)

  if (roundedAvgT < 46) return "No infect; temp<46"
  if (roundedAvgT > 86) return "Unknown infect; temp>86"
  if (totPrcp >= 0.01 && wetHours >= phomopDict[roundedAvgT]) return "Infection"
  return "No infection"
}

function determineBlackRotInfection(wetHours, avgT, totPrcp) {
  const roundedAvgT = Math.round(avgT)
  const tempToNearest5 = roundToNearest5(avgT)

  if (roundedAvgT < 50) return "No infect; temp<50"
  if (roundedAvgT > 90) return "Unknown infect; temp>90"
  if (totPrcp >= 0.01 && wetHours >= blackRotDict[tempToNearest5])
    return "Infection"
  return "No infection"
}

export default function grapeDiseasesLogic(data, dateOfInterest) {
  const { hourlyData, dailyData } = data
  let march25Idx = hourlyData.findIndex((d) =>
    isEqual(d.date, new Date(dateOfInterest.year, 2, 25, 0))
  )
  if (march25Idx === -1) march25Idx = 0

  let wetEvents = []
  let wetEvent = []
  hourlyData.slice(march25Idx).forEach((d, i) => {
    if (d.lwet >= 30 || d.prcp > 0) {
      wetEvent.push(d)
      if (i + march25Idx === hourlyData.length - 1) wetEvents.push(wetEvent)
    } else {
      if (wetEvent.length !== 0) {
        wetEvents.push(wetEvent)
        wetEvent = []
      }
    }

    return d
  })
  // console.log(wetEvents)

  const leafWetnessEventsLog = wetEvents.map((wetEvent) => {
    const wetEventStartDateTime = format(
      subHours(wetEvent[0].date, 1),
      "M/d/yyyy h:00 aaa"
    )

    const wetEventEndDateTime = format(
      wetEvent.slice(-1)[0].date,
      "M/d/yyyy h:00 aaa"
    )

    const wetHours = wetEvent.length

    const temps = wetEvent.map((d) => d.temp).filter((t) => t !== "M")
    const avgT = temps.reduce((acc, val) => acc + val, 0) / temps.length

    const prcps = wetEvent
      .map((d) => d.prcp)
      .filter((d) => d !== "M")
      .filter(Boolean)
    const totalPrcp = prcps.reduce((acc, val) => acc + val, 0)

    return {
      data: [...wetEvent],
      wetHours,
      avgT,
      totalPrcp,
      phomopsis: determinePhomopsisInfection(wetHours, avgT, totalPrcp),
      blackRot: determineBlackRotInfection(wetHours, avgT, totalPrcp),
      wetEventStartDateTime,
      wetEventEndDateTime,
    }
  })
  // console.log(leafWetnessEventsLog)
  const firstEventWithPrcpIndices = leafWetnessEventsLog
    .map((we, i) => {
      const wh = we.data.findIndex((d) => d.prcp > 0)
      if (wh !== -1) {
        return { i, wh }
      } else {
        return null
      }
    })
    .filter(Boolean)[0]

  const onlyWetEventsSliced = leafWetnessEventsLog
    .slice(firstEventWithPrcpIndices.i)
    .map((d, i) => {
      if (i === 0) {
        const newData = d.data.slice(firstEventWithPrcpIndices.wh)
        return newData
      } else {
        return d.data
      }
    })

  let allCombined = []
  let combined = []
  onlyWetEventsSliced.forEach((we, i) => {
    if (i === 0) {
      combined = [...we]
      // console.log("first:", i, combined)
    } else {
      const firstDate = we[0].date
      const lastDate = onlyWetEventsSliced[i - 1].slice(-1)[0].date
      const wetPeriodNoPrcp = we.find((d) => d.prcp > 0)

      if (
        differenceInHours(firstDate, lastDate) > 24 ||
        typeof wetPeriodNoPrcp === "undefined"
      ) {
        if (typeof wetPeriodNoPrcp === "undefined") {
          combined = [...combined, ...we]
          allCombined.push(combined)
          combined = []
        } else {
          if (combined.length !== 0) allCombined.push(combined)
          combined = [...we]
        }
      } else {
        combined = [...combined, ...we]
        if (i === onlyWetEventsSliced.length - 1 && combined.length !== 0)
          allCombined.push(combined)
      }
    }
  })
  // console.log(allCombined)

  const grapeInfectionEventsLog = allCombined
    .map((d) => {
      const wetEventStartDateTime = format(
        subHours(d[0].date, 1),
        "M/d/yyyy h:00 aaa"
      )
      const wetEventEndDateTime = format(
        d.slice(-1)[0].date,
        "M/d/yyyy h:00 aaa"
      )

      const wetHours = d.length
      const temps = d.map((d) => d.temp).filter((t) => t !== "M")
      const avgT = temps.reduce((acc, val) => acc + val, 0) / temps.length

      const prcps = d
        .map((d) => d.prcp)
        .filter((d) => d !== "M")
        .filter(Boolean)
      const totalPrcp = prcps.reduce((acc, val) => acc + val, 0)

      const phomopsis = determinePhomopsisInfection(wetHours, avgT, totalPrcp)
      const blackRot = determineBlackRotInfection(wetHours, avgT, totalPrcp)

      if (phomopsis === "Infection" || blackRot === "Infection") {
        return {
          data: d,
          wetHours,
          avgT,
          totalPrcp,
          phomopsis,
          blackRot,
          wetEventStartDateTime,
          wetEventEndDateTime,
        }
      } else {
        return null
      }
    })
    .filter(Boolean)
  // console.log(grapeInfectionEventsLog)

  // console.log(leafWetnessEventsLog)
  const powderyMildew = dailyData.map((day) => {
    let powRisk = "No"
    const averageT = (day.mint + day.maxt) / 2

    const prcps = day.prcp
      .filter((d) => d !== "M")
      .filter((d) => typeof d !== "undefined")

    // console.log(day)
    let totalPrcp
    if (prcps.length !== 0) {
      totalPrcp = prcps.reduce((acc, val) => acc + val, 0)
      if (totalPrcp >= 0.1 && averageT >= 50) powRisk = "Yes"
    } else {
      powRisk = "-"
    }

    let powRiskCellColor = ""
    if (powRisk === "No") powRiskCellColor = "bg-green-100 text-green-800"
    if (powRisk === "Yes") powRiskCellColor = "bg-red-100 text-red-800"

    return {
      date: day.date,
      averageT,
      totalPrcp,
      isForecast: day.isForecast,
      dayOfYear: day.dayOfYear,
      powRisk,
      powRiskCellColor,
      phoRisk: "No infection",
      blkRisk: "No infection",
    }
  })
  // console.log(powderyMildew)

  const diseaseInfectionEvents = powderyMildew.map((day) => {
    let p = {
      ...day,
      dateDisplay: format(day.date, "M/d/yyyy"),
    }

    grapeInfectionEventsLog.forEach((event) => {
      const startDate = event.data[0].date
      const endDate = event.data.slice(-1)[0].date

      // console.log(endDate, day.date, isEqual(endDate, day.date))
      if (isSameDay(endDate, day.date)) {
        if (p.phoRisk !== "Infection") p.phoRisk = event.phomopsis
        if (p.blkRisk !== "Infection") p.blkRisk = event.blackRot
      } else {
        // date of interest >= start date of event &&  date of interest < end date
        if (
          (isSameDay(startDate, day.date) || isAfter(startDate, day.date)) &&
          isBefore(endDate, day.date)
        ) {
          if (event.phomopsis === "Infection") p.phoRisk = "Combined"
          if (event.blackRot === "Infection") p.blkRisk = "Combined"
        }
      }

      if (p.phoRisk === "Infection") {
        p.phoRiskCellColor = "bg-red-100 text-red-800"
        p.phoRiskValue = "Yes"
      } else if (p.phoRisk === "No infection") {
        p.phoRiskCellColor = "bg-green-100 text-green-800"
        p.phoRiskValue = "No"
      } else if (p.phoRisk === "Combined") {
        p.phoRiskCellColor = "bg-red-100 text-red-800"
        p.phoRiskValue = "Combined"
      } else if (p.phoRisk === "No infect; temp<46") {
        p.phoRiskCellColor = "bg-yellow-100 text-yellow-800"
        p.phoRiskValue = "No; temp&lt;46"
      } else if (p.phoRisk === "Unknown infect; temp>86") {
        p.phoRiskCellColor = "bg-yellow-100 text-yellow-800"
        p.phoRiskValue = "Unknown; temp&gt;86"
      } else {
        p.phoRiskCellColor = ""
        p.phoRiskValue = "-"
      }

      if (p.blkRisk === "Infection") {
        p.blkRiskCellColor = "bg-red-100 text-red-800"
        p.blkRiskValue = "Yes"
      } else if (p.blkRisk === "No infection") {
        p.blkRiskCellColor = "bg-green-100 text-green-800"
        p.blkRiskValue = "No"
      } else if (p.blkRisk === "Combined") {
        p.blkRiskCellColor = "bg-red-100 text-red-800"
        p.blkRiskValue = "Combined"
      } else if (p.blkRisk === "No infect; temp<50") {
        p.blkRiskCellColor = "bg-yellow-100 text-yellow-800"
        p.blkRiskValue = "No; temp&lt;50"
      } else if (p.blkRisk === "Unknown infect; temp>90") {
        p.blkRiskCellColor = "bg-yellow-100 text-yellow-800"
        p.blkRiskValue = "Unknown; temp&gt;90"
      } else {
        p.blkRiskCellColor = ""
        p.blkRiskValue = "-"
      }
    })

    return p
  })
  // console.log(diseaseInfectionEvents)
  return {
    diseaseInfectionEvents,
    leafWetnessEventsLog,
    grapeInfectionEventsLog,
  }
}
